import React from 'react';
import { useTranslation } from "react-i18next";

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

// components
import Helmet from 'components/Helmet/Helmet';

const Error = (props) => {
	const { t } = useTranslation();

	const toForm = () => {
		props.setError({
			...props.error,
			error: false
		})
	}

	return (
		<>
					
			<Helmet>
				<title>{t('booking_error')} : {t('common_ihic')}</title>
			</Helmet>

			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_booking')}</span>
					<span className="c-pageHeader__eng">BOOKING</span>
				</div>
			</h1>

			<div className="p-booking">
				
				<div className="p-booking-message">
					<p className="p-booking-message__heading"><FontAwesomeIcon icon={faExclamationCircle} />{t('booking_error')}</p>
					<p className="p-booking-message__text">{t('booking_info_text')}</p>
					<p className="p-booking-message__address" dangerouslySetInnerHTML={{__html: t('booking_cancel_tell')}}></p>
				</div>
				<div className="c-form-container">
					<p className="c-info__link"
						style={{
							textAlign: "center"
						}}
					>
						<button
							onClick={()=>{toForm()}}
							className="c-btn c-btn--regular"
							style={{margin: "0 auto"}}
						>
							{t('booking_back_to')}
						</button>
					</p>
				</div>
			</div>
		</>
	);
}

export default Error;
