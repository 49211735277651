import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

export default function Footer() {
	const { t } = useTranslation();
	const location = useLocation();
	const defaultLng = getLocale();

	const buildBanner = () => {
		const result = location.pathname.indexOf('/topics');

		if (location.pathname === "/" || result !== -1) {
			return (
				<div className="c-footer-bnr__wrapper">
					<p className="c-footer-bnr">
						<span className="c-footer-bnr__title">{t('common_banner_title')}</span>
						<a href="https://www.ncih.jp/" target="_blank" rel="noopener noreferrer" className="c-footer-bnr__anchor">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/bnr-sangyoisankokuminkaigi.png')} alt="産業遺産国民会議" className="c-footer-bnr__img"/>
							<span className="c-footer-bnr__note">{t('common_banner_text')}</span>
						</a>
					</p>
				</div>
			)
		} else {
			return null
		}
	}

	return (
		<>
			<footer className="l-footer">
				<ul className="c-footer-nav__list">
					<li className="c-footer-nav__item">
						<a href={`https://www.ihic.jp/l/${defaultLng}/guidebook`} className="c-footer-nav__anchor">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-footernav--guidebook.png')} alt="" className="c-footer-nav__img" />
							{t('common_guidebook')}
						</a>
					</li>
					<li className="c-footer-nav__item">
						<a href={`https://www.ihic.jp/l/${defaultLng}/publications`} className="c-footer-nav__anchor">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-footernav--books.png')} alt="" className="c-footer-nav__img" />
							{t('common_publications')}
						</a>
					</li>
					<li className="c-footer-nav__item">
						<a href={`https://www.ihic.jp/l/${defaultLng}/industrial`} className="c-footer-nav__anchor">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-footernav--info.png')} alt="" className="c-footer-nav__img" />
							<span className=" c-footer-nav__anchor__info">{t('common_component')}</span>
						</a>
					</li>
				</ul>
				<address className="c-footer-address">
					<p className="c-footer-address__heading">{t('common_ihic')}</p>
					<p className="c-footer-address__text" dangerouslySetInnerHTML={{__html:t('common_address_footer')}} />
					<p className="c-footer-address__text">{t('common_address_footer_tell')}</p>
				</address>
				{buildBanner()}
				<ul className="c-footer-navUtil__list">
					<li className="c-footer-navUtil__item"><a href={`https://www.ihic.jp/l/${defaultLng}/privacy`} className="c-footer-navUtil__anchor">{t('common_privacy')}</a></li>
					<li className="c-footer-navUtil__item"><a href={`https://www.ihic.jp/l/${defaultLng}/sitemap`} className="c-footer-navUtil__anchor">{t('common_sitemap')}</a></li>
				</ul>
				<p className="c-copyright">Copyright 2020 Industrial Heritage Information Centre <br className="forSP"/>All Rights Reserved.</p>
			</footer>
		</>
	);
};
