import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Calendar from 'react-calendar';
import classNames from 'classnames';
import { rest, getLocale } from "@karpeleslab/klbfw";
import moment from "moment";
import _ from 'lodash';
import * as Scroll from 'react-scroll';
import { useTranslation } from "react-i18next";

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// components
import Error from 'components/Error/Error';
import Helmet from 'components/Helmet/Helmet';

// styles
import styles from 'Scss/page.module.scss';

const Index = () => {
	const history = useHistory();
	const defaultLng = getLocale();
	const [load, setLoad] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM-DD'));
	const [currentSlots, setCurrentSlots] = useState({
		show: false,
		day: null,
		date: null,
	});
	const [events, setEvents] = useState([]);
	const [eventsNext, setEventsNext] = useState([]);
	const [steps, setSteps] = useState({
		step1: true,
		step2: false,
		step3: false
	})

	const step2 = useRef(null);
	const step3 = useRef(null);

	const [error, setError] = useState({
		error: false,
		first_name: false,
		last_name: false,
		phone: false,
		email: false,
		count: false,
		date: false,
		privacy: false
	})

	const [slots, setSlots] = useState(null);

	const [formVal, setFormVal] = useState({
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		count: "",
		day: "",
		month: "",
		year: "",
		slot: "",
		privacy: false
	});

	const [messageVal, setMessageVal] = useState("");

	const [visitors, setVisitors] = useState(
		{
			max: 10,
			current: 0,
		}
	);
	const calFormat = {
		'en-US': 'M/YYYY',
		'ja-JP': 'YYYY年MM月',
		'ko-KR': 'YYYY.MM',
		'full-en-US': 'MM/DD/YYYY',
		'full-ja-JP': 'YYYY/MM/DD',
		'full-ko-KR': 'YYYY.MM.DD',
	}

	const nameInput = {
		'en-US': {
			ptn: "^([a-zA-Z]|ー)+$"
		},
		'ja-JP': {
			ptn: "^([ァ-ン]|ー)+$"
		},
		'ko-KR': {
			ptn: "^([a-zA-Z]|ー)+$"
		}
	}

	const { t } = useTranslation();


	useEffect(() => {
		setLoad(false);
		rest('IHIC/@current/Booking:getCalendar', 'GET', {month: moment(currentMonth).format('MM'), year: moment(currentMonth).format('YYYY')})
			.then(
				(data) => {
					setLoad(true);
					setEvents(data.data)
				}
			)
			.catch(
				(data) => {
					setLoad(true);
				}
			)
		
		rest('IHIC/@current/Booking:getCalendar', 'GET', {month: moment(currentMonth).add({month: 1}).format('MM'), year: moment(currentMonth).add({month: 1}).format('YYYY')})
			.then(
				(data) => {
					setLoad(true);
					setEventsNext(data.data)
				}
			)
			.catch(
				(data) => {
					setLoad(true);
				}
			)

	}, [currentMonth]);

	useEffect(() => {
		setLoad(false);
		rest('IHIC/@current/Booking:getCalendar', 'GET', {month: moment(currentMonth).format('MM'), year: moment(currentMonth).format('YYYY')})
			.then(
				(data) => {
					setLoad(true);
					setEvents(data.data)
				}
			)
			.catch(
				(data) => {
					setLoad(true);
					setEvents(data.data)
				}
			)
		
		rest('IHIC/@current/Booking:getCalendar', 'GET', {month: moment(currentMonth).add({month: 1}).format('MM'), year: moment(currentMonth).add({month: 1}).format('YYYY')})
			.then(
				(data) => {
					setLoad(true);
					setEventsNext(data.data)
				}
			)
			.catch(
				(data) => {
					setLoad(true);
					setEventsNext(data.data)
				}
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visitors])

	useEffect(() => {
		const checkArray = _.intersection(_.values(formVal), ["", false]);
		const phoneArray = _.intersection(_.values(formVal.phone), ["-", false]);

		if (checkArray.length > 0 || phoneArray.length > 0) {
			setFormDisabled(true);
		} else {
			setFormDisabled(false);
		}

	}, [formVal]);

	useEffect(() => {
		// rest('IHIC/@current/Booking:getOpenings', 'GET', {day: z, month: x, year: y})

		if (currentSlots.show && currentSlots.day !== null) {

			rest('IHIC/@current/Booking:getOpenings', 'GET', {
				day: moment(currentSlots.date).format('DD'),
				month: moment(currentSlots.date).format('MM'),
				year: moment(currentSlots.date).format('YYYY')
			})
				.then(
					(data) => {
						setSlots(data.data)
					}
				)
				.catch(
					(data) => {
						setSlots(null)
					}
				)

		} else if (!currentSlots.show){
			setSlots(null)
		}

	}, [currentSlots]);

	const submitHandler = (e) => {
		e.preventDefault();

		setFormDisabled(true);

		const query = {
			day: formVal.day,
			month: formVal.month,
			year: formVal.year,
			count: formVal.count,
			slot: formVal.slot,
			email: formVal.email,
			phone: formVal.phone,
			first_name: formVal.first_name,
			last_name: formVal.last_name,
			message: messageVal
		};

		rest('IHIC/@current/Booking:reserve', 'POST', query)
			.then(
				() => {
					history.push('/thankyou');
				}
			)
			.catch(
				(data) => {
					setError({
						...error,
						error: true
					});
				}
			)

	}

	const tileClickHandler = (date) => {

		setFormVal({
			...formVal,
			day: "",
			month: "",
			year: "",
			slot: "",
		});

		setCurrentSlots({
			show: true,
			day: moment(date).format('YYYY/MM/DD'),
			date: moment(date).format('YYYY-MM-DD')
		});

		setSteps({
			...steps,
			step2: true,
			step3: false
		})

	}
	
	const tileDisableHandler = (date) => {
		const calDay = moment(date.date).format('YYYYMMDD');
		const toDay = moment().format('YYYYMMDD');
		const currentEvent = _.find(events, { 'date': moment(date.date).format('YYYY-MM-DD') });

		if (currentEvent === void 0) {
			return 1
		}
		else {
			if (calDay <= toDay || currentEvent.status === 'batsu' || currentEvent.max　< visitors.current ) {
				return 1
			}
		}

	}

	const tileHandler = (data) => {
		const calDay = moment(data.date).format('YYYYMMDD');
		const toDay = moment().format('YYYYMMDD');
		const eventData = moment(data.date).format('YYYYMM') === moment(currentMonth).format('YYYYMM') ? events : eventsNext;
		const currentEvent = _.find(eventData, { 'date': moment(data.date).format('YYYY-MM-DD') });

		if (currentEvent === void 0) {
			return 'c-booking-calendar__data'
		}
		else {
			// 予約枠無
			if (currentEvent.status === 'batsu' && currentEvent.available === 0 && currentEvent.max === 0 &&  currentEvent.openings === 0 ) {
				return 'c-booking-calendar__data'
			}
			else {
				// Past time
				if (calDay <= toDay || currentEvent.status === 'batsu' || currentEvent.max　< visitors.current ) {
					return 'c-booking-calendar__data is-past'
				}
				// event
				else if (currentEvent.status === 'maru') {
					return `c-booking-calendar__data is-available`
				}
				else if (currentEvent.status === 'sankaku') {
					return 'c-booking-calendar__data is-caution'
				}
			}
			
		}
	}

	const activeStartHandler = ({activeStartDate}) => {
		setLoad(false);
		setCurrentMonth(moment(activeStartDate).format('YYYYMMDD'))
	}

	const buildLoad = () => {
		return (
			<div className={styles['loading']}>
				<FontAwesomeIcon icon={faSpinner} />
			</div>
		)
	}


	const buildCalendar = (month = null, next = null) => {

		const setMonth = month === null ? currentMonth : month;

		if (load) {
			return (
				<Calendar
					locale={defaultLng}
					tileClassName={tileHandler}
					tileDisabled={tileDisableHandler}
					defaultView="month"
					defaultValue={[moment(setMonth)._d]}
					minDetail="month"
					onActiveStartDateChange={activeStartHandler}
					onClickDay={tileClickHandler}
					showNavigation={false}
					className="c-booking-calendar"
				/>
			)
		} else {
			return (
				buildLoad()
			)
		}
	}


	const setVisitorsVal = (event) => {
		
		setFormVal({
			...formVal,
			day: "",
			month: "",
			year: "",
			slot: "",
			count: event.target.value,
		});

		setSlots(null);

		setVisitors({
			...visitors,
			current: event.target.value
		});

		setCurrentSlots({
			show: false,
			day: null,
			date: null,
		});

		if (event.target.value === '') {
			setSteps({
				...steps,
				step2: false,
				step3: false
			})
		} else {
			setSteps({
				...steps,
				step2: true,
				step3: false
			});

			setTimeout(() => {
				const clientRect = step2.current.offsetTop - 20;
				Scroll.animateScroll.scrollTo(clientRect);
			},500)
		}

	}

	const buildVisitors = () => {
		const list = []
		
		for (let num = 1; num <= visitors.max; num++) {
			list.push(
				<option
					key={num}
					value={num}
				>{num}{t('booking_unit')}</option>
			);
		}

		return (
			<select
				className="c-form-select js-booking--headcount"
				onChange={setVisitorsVal}
				onBlur={errorCheck}
				value={formVal.count}
				name="visitors"
			>
				<option value="">{t('booking_1to10')}</option>
				{list}
			</select>
		);
	}

	const slotClick = (event) => {
		const year = moment(currentSlots.date).format('YYYY');
		const month = moment(currentSlots.date).format('MM');
		const day = moment(currentSlots.date).format('DD');
		const slot = event.currentTarget.getAttribute('data-time');

		setFormVal({
			...formVal,
			day: day,
			month: month,
			year: year,
			slot: slot
		});

		setSteps({
			...steps,
			step3: true
		})

		setTimeout(() => {
			const clientRect = step3.current.offsetTop - 20;
			Scroll.animateScroll.scrollTo(clientRect);
		},500)

	}

	const buildSlot = (item, idx) => {

		const availableNum = item.available;
		let currentVisit = visitors.current;

		if (currentVisit === null) currentVisit = 0;
		

		if (availableNum !== 0 && availableNum > 0 &&  availableNum >= currentVisit) {
			return (

				<button
					type="button"
					className={classNames("p-booking-time__btn js-booking--time", {
						'is-active': formVal.slot === item.slot
					})}
					key={idx}
					onClick={slotClick}
					data-time={item.slot}
					data-diff={availableNum}
				>
					<span className="p-booking-time__time">{item.slot}</span>
					<span className="p-booking-time__number">{t('booking_vacancies')}：{availableNum}{t('booking_vacancies_unit')}</span>
				</button>
			)
		}
		else if (availableNum !== 0 && availableNum > 0 &&  availableNum < currentVisit) {
			return (
				<div
					className="p-booking-time__btn js-booking--time is-notAvailable"
					disabled
				>
					<span className="p-booking-time__time">{item.slot}</span>
					<span className="p-booking-time__number">{t('booking_vacancies')}：{availableNum}{t('booking_vacancies_unit')}</span>
				</div>
			)
		}
		else {
			return (
				<div
					key={idx}
					className="p-booking-time__btn js-booking--time is-notAvailable"
					disabled
				>
					<dt>{item.slot}</dt>
					<dd>{t('booking_vacancies_full')}</dd>
				</div>
			)
		}

	}

	const buildSlots = (guide) => {

		if (slots !== null) {
			const slotsGuide = _.filter(slots,{guide: guide});
			return (
				<div className="p-booking-time__btnContainer">
					{slotsGuide.length === 0 && <p className="p-booking-time__text_label">{t("booking_chose_slot_guide_not")}</p>}
					{slotsGuide.map((item, idx) => {
						return buildSlot(item, idx);
					})}
				</div>
			)
		}
		else {
			return (
				buildLoad()
			)
		}
	}

	const buildShowSlots = () => {
		if (currentSlots.show) {
			return (
				<>
					<p className="p-booking-time__date">{moment(currentSlots.day).format(calFormat[`full-${defaultLng}`])}</p>
					<p className="p-booking-time__text">{t('booking_chose_slot')}</p>
					<p className="p-booking-time__text_label_title">{t('booking_chose_slot_noguide')}</p>
					{buildSlots("N")}
					<p className="p-booking-time__text_label_title">{t('booking_chose_slot_guide')}</p>
					{buildSlots("Y")}
				</>
			)
		} else {
			return (
				<>
					<p className="p-booking-time__date">{t('booking_chose_date_inner')}</p>
				</>
			)
		}
	}

	const inputVal = (event) => {

		const type = event.target.getAttribute('type');
		errorCheck(event, type)

		if (type === 'checkbox') {
			setFormVal({
				...formVal,
				privacy: event.target.checked
			});
		} else if (type === 'text' || type === 'email' || type === 'tel' || type === null) {

			const array = {}
			array[event.target.getAttribute('name')] = event.target.value;
	
			setFormVal({
				...formVal,
				...array
			});
		}
	}

	const textAreaVal = (event) => {
		setMessageVal(event.target.value)
	}

	const buildError = (names) => {
		const errorArray = names.map((item) => {
			return error[item]
		});


		if (_.indexOf(errorArray, true) !== -1) {
			switch (names[0]) {
			case 'privacy' :
				return (
					<p className={styles['error-input']}>{t('booking_error_check')}</p>
				);
			case 'visitors':
				return (
					<p className={styles['error-input']}>{t('booking_error_choice')}</p>
				);
			case 'email':
				return (
					<p className={styles['error-input']}>{t('booking_error_input')}</p>
				);
			case 'phone-haifun':
				return (
					<p className={styles['error-input']}>{t('booking_error_haifun')}</p>
				);
			default:
				return (
					<p className={styles['error-input']}>{t('booking_error_input')}</p>
				);
			}
		} else {
			return null
		}
	}

	const errorCheck = (event) => {

		const type = event.target.getAttribute('type');
		const name = event.target.getAttribute('name');

		if (type === 'checkbox') {
			const errorCheckBox = {};
			if (event.target.checked) {
				errorCheckBox.privacy = false;
			} else {
				errorCheckBox.privacy = true;
			}
			setError({
				...error,
				...errorCheckBox
			})
		} else if (type === 'text' || type === null || type === 'email') {
			// error
			const valNum = event.target.value.length;
			const errorArray = {};
			if (valNum === 0) {
				errorArray[event.target.getAttribute('name')] = true;
			} else {
				errorArray[event.target.getAttribute('name')] = false;
			}
			setError({
				...error,
				...errorArray
			})
		} else if (type === 'tel') {

			// no input
			const valNum = event.target.value.length;
			const errorArray = {};
			if (valNum === 0) {
				errorArray[`${name}-input`] = true;
			} else {
				errorArray[`${name}-input`] = false;
			}

			// -が入っていたら
			const result = event.target.value.indexOf( '-' );

			if (result !== -1) {
				errorArray[`${name}-haifun`] = true;
			} else {
				errorArray[`${name}-haifun`] = false;
			}

			const resultArray = _.map(errorArray, (val) => {
				return val;
			});
			
			if (resultArray.indexOf(true) !== -1) {
				errorArray['phone'] = true
			} else {
				errorArray['phone'] = false
			}

			setError({
				...error,
				...errorArray
			})
		}
	}

	const buildCalendarWrapper = () => {

		

		if (moment().format('YYYYMM') === moment(currentMonth).format('YYYYMM')) {
			return (
				<div className="js-booking--calendar">
					{buildCalendar(moment().format('YYYY-MM-DD'))}
				</div>
			)
		}
		else {
			return (
				<div className="js-booking--calendar">
					{buildCalendar(moment().add({month: 1}).format('YYYY-MM-DD'), 'next')}
				</div>
			)
		}
	}


	const buildStep2 = () => {
		if (steps.step2 === true) {
			return (
				<div ref={step2} className="p-booking-step p-booking-step--02">
					<div className="p-booking-step__head">
						<p className="p-booking-step__number">{t('booking_step2')}</p>
						<p className="p-booking-step__heading" dangerouslySetInnerHTML={{__html:t('booking_chose_date')}}></p>
					</div>
					<div className="p-booking-step__body">
						<div className="p-booking-tab__container">
							<div className="p-booking-tab__nav">
								<button type="button" onClick={() => {setCurrentMonth(moment().format('YYYY-MM-DD'))}} className={classNames("p-booking-tab__navItem js-booking--tab", {
									'is-active': moment().format('YYYYMM') === moment(currentMonth).format('YYYYMM')
								})} data-target="js-booking--calendar1">{moment().format(calFormat[defaultLng])}</button>
								<button type="button" onClick={() => {setCurrentMonth(moment().add({month: 1}).format('YYYY-MM-DD'))}} className={classNames("p-booking-tab__navItem js-booking--tab", {
									'is-active': moment().add({month: 1}).format('YYYYMM') === moment(currentMonth).format('YYYYMM')
								})} data-target="js-booking--calendar2">{moment().add({month: 1}).format(calFormat[defaultLng])}</button>
							</div>
							<div className="p-booking-tab__body">
								{buildCalendarWrapper()}
							</div>
						</div>
						<div className="p-booking-time">
							{buildShowSlots()}
						</div>
					</div>
				</div>
			)
		} else {
			return null
		}
	}

	const buildLang = () => {

		if (defaultLng === 'en-US') {
			return (
				<>
					<div className="c-form-container is-half">
						<label htmlFor="name_mei" className="c-form-label">{t('booking_first_name')}</label>
						<input
							name="first_name"
							className="c-form-text"
							placeholder={t('booking_first_name_holder')}
							type="text"
							onChange={inputVal}
							onBlur={errorCheck}
							value={formVal.first_name}
							pattern={nameInput[defaultLng]['ptn']}
						/>
						{buildError(['first_name'])}
					</div>
					<div className="c-form-container is-half">
						<label htmlFor="name_sei" className="c-form-label">{t('booking_last_name')}</label>
						<input
							name="last_name"
							className="c-form-text"
							type="text"
							onChange={inputVal}
							onBlur={errorCheck}
							value={formVal.last_name}
							placeholder={t('booking_last_name_holder')}
							pattern={nameInput[defaultLng]['ptn']}
						/>
						{buildError(['last_name'])}
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="c-form-container is-half">
						<label htmlFor="name_sei" className="c-form-label">{t('booking_last_name')}</label>
						<input
							name="last_name"
							className="c-form-text"
							type="text"
							onChange={inputVal}
							onBlur={errorCheck}
							value={formVal.last_name}
							placeholder={t('booking_last_name_holder')}
							pattern={nameInput[defaultLng]['ptn']}
						/>
						{buildError(['last_name'])}
					</div>
					<div className="c-form-container is-half">
						<label htmlFor="name_mei" className="c-form-label">{t('booking_first_name')}</label>
						<input
							name="first_name"
							className="c-form-text"
							placeholder={t('booking_first_name_holder')}
							type="text"
							onChange={inputVal}
							onBlur={errorCheck}
							value={formVal.first_name}
							pattern={nameInput[defaultLng]['ptn']}
						/>
						{buildError(['first_name'])}
					</div>
				</>
			)

		}

	}

	const buildStep3 = () => {
		if (steps.step3 === true) {
			return (
				<div ref={step3} className="p-booking-step p-booking-step--03">
					<div className="p-booking-step__head">
						<p className="p-booking-step__number">{t('booking_step3')}</p>
						<p className="p-booking-step__heading"><span className="p-booking-step__headingInner">{t('booking_entry')}</span></p>
					</div>
					<div className="p-booking-step__body">
						<div className="p-booking-customerInfo">
							{buildLang()}
							<div className="c-form-container is-half">
								<label htmlFor="email" className="c-form-label">{t('booking_email')}</label>
								<input
									name="email"
									className="c-form-text"
									placeholder="example@ihic.jp"
									type="email"
									onChange={inputVal}
									onBlur={errorCheck}
									value={formVal.email}
								/>
								{buildError(['email'])}
							</div>
							<div className="c-form-container is-half">
								<label htmlFor="tel" className="c-form-label">{t('booking_tell')}</label>
								<input
									name="phone"
									className="c-form-text"
									placeholder={t('booking_tell_holder')}
									type="tel"
									onChange={inputVal}
									onBlur={errorCheck}
									value={formVal.phone}
									pattern="^[0-9]+$"
								/>
								{buildError(['phone-input'])}
								{buildError(['phone-haifun'])}
							</div>
							<div className="c-form-container is-full">
								<label htmlFor="message" className="c-form-label">{t('booking_message')}</label>
								<textarea
									name="message"
									className="c-form-textArea"
									placeholder={t('booking_massege_etc')}
									onChange={textAreaVal}
									value={messageVal}
								></textarea>
							</div>
							<div className="c-form-container p-booking-privacy">

								<input
									name="privacy"
									type="checkbox"
									id="privacy"
									value={formVal.privacy}
									onChange={inputVal}
									onBlur={errorCheck}
									className="c-form-checkbox"
									checked={formVal.privacy}
								/>
								<label htmlFor="privacy" className="c-form-label c-form-label--checkbox" dangerouslySetInnerHTML={{__html: t('booking_massege_privacy')}}></label>
							</div>
							<div className="c-form-container p-booking-submit">
								<button
									className="c-btn c-btn--reserve"
									disabled={formDisabled}
									type="submit"
								>{t('booking_booking')}</button>
							</div>
						</div>
					</div>
				</div>
			)
		} else {
			return null
		}
	}



	if (error.error) {
		return <Error
			error={error}
			setError={setError}
		/>
	} else {
		return (
			<>
					
				<Helmet>
					<title>{t('common_booking')} : {t('common_ihic')}</title>
				</Helmet>

				<h1 className="c-pageHeader">
					<div className="c-pageHeader__container">
						<span className="c-pageHeader__jp">{t('common_booking')}</span>
						<span className="c-pageHeader__eng">BOOKING</span>
					</div>
				</h1>

				<div className="c-topicPath c-block">
					<ol className="c-topicPath__list">
						<li className="c-topicPath__item"><a href={`https://www.ihic.jp/l/${defaultLng}/`} className="c-topicPath__anchor">{t('common_home')}</a></li>
						<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><a href={`https://www.ihic.jp/l/${defaultLng}/guide`} className="c-topicPath__anchor">{t('common_visiting')}</a></li>
						<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_booking')}</li>
					</ol>
				</div>



				<div className="p-booking">

					<div className="p-booking-info">
						<p className="p-booking-info__text" dangerouslySetInnerHTML={{__html: t('booking_info')}}></p>
						<p className="p-booking-info__text p-booking-info__text__strong" dangerouslySetInnerHTML={{__html: t('booking_info_2')}}></p>
						<p className="p-booking-info__text">{t('booking_info_1')}</p>
					</div>


					<div className="p-booking-steps js-booking">
						<form
							onSubmit={submitHandler}
						>
							<div className="p-booking-step p-booking-step--01">
								<div className="p-booking-step__head">
									<p className="p-booking-step__number">{t('booking_step1')}</p>
									<p className="p-booking-step__heading"><span className="p-booking-step__headingInner">{t('booking_how_many')}</span></p>
								</div>
								<div className="p-booking-step__body">
									<span className="c-form-select__container">
										{buildVisitors()}
										<span className="c-form-select__bullet" style={{ backgroundImage: `url(${require('assets/img/rocket-on/img-arrow-t2b--black.svg')})`}}></span>
									</span>
								</div>
							</div>
							{buildStep2()}
							{buildStep3()}
						</form>
					</div>


					<div className="p-booking-message">
						<p className="p-booking-message__heading">{t('booking_cancel')}</p>
						<p className="p-booking-message__text">{t('booking_cancel_text')}</p>
						<p className="p-booking-message__address" dangerouslySetInnerHTML={{__html: t('booking_cancel_tell')}}></p>
					</div>
				</div>
			</>
		);
	}
}

export default Index;
