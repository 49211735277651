import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import Layout from 'components/Layout/Layout';
import Index from 'components/Pages/Index/Index';
import Thankoyou from 'components/Pages/Thankyou/Thankyou';

const Pages = () => {
	return <Layout>
		<Switch>
			<Route exact path="/">
				<Index/>
			</Route>
			<Route exact path="/thankyou">
				<Thankoyou/>
			</Route>
			<Route>
				<Redirect to=""/>
			</Route>
		</Switch>
	</Layout>
}

const Core = () => {
	// user is not logged in
	return (
		<Switch>
			<Route>
				<Pages/>
			</Route>
		</Switch>
	);
}

const Routing = () => {
	return <Core/>
}

export default Routing;
