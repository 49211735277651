import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import Helmet from 'components/Helmet/Helmet';

const Thankyou = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
					
			<Helmet>
				<title>{t('common_booking')} : {t('common_ihic')}</title>
			</Helmet>

			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_booking')}</span>
					<span className="c-pageHeader__eng">BOOKING</span>
				</div>
			</h1>

			<div className="p-booking">

				
				<div className="p-booking-message">
					<p className="p-booking-message__heading">{t('booking_comp')}</p>
					<p className="p-booking-message__text" dangerouslySetInnerHTML={{__html: t('booking_comp_text')}}></p>
				</div>

				
				<div className="p-booking-message">
					<p className="p-booking-message__heading">{t('booking_cancel')}</p>
					<p className="p-booking-message__text">{t('booking_cancel_text')}</p>
					<p className="p-booking-message__address" dangerouslySetInnerHTML={{__html: t('booking_cancel_tell')}}></p>
				</div>
				<div className="c-form-container">
					<p className="c-info__link"
						style={{
							textAlign: "center"
						}}
					>
						<Link
							to="/"
							className="c-btn c-btn--regular"
							style={{margin: "0 auto"}}
						>
							{t('booking_back_to')}
						</Link>
					</p>
				</div>
			</div>
		</>
	);
}

export default Thankyou;
