import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import moment from "moment";
import classNames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

export default function Header() {
	const { t } = useTranslation();
	const defaultLng = getLocale();
	const location = useLocation();
	const [ navOpen, setNavOpen ] = useState(false);
	const [ langModal, setLangModal ] = useState(false);
	const [ toggleLock, setToggleLock ] = useState(false);
	const [ scrollLock, setScrollLock ] = useState(false);
	const imageVariation = 'format=jpeg&strip&scale_crop=374×247';
	const [topicsList] = useRest("Content/Cms/@news:search",{
		results_per_page:4,
		query: {
			tag: 'events'
		},
		"image_variation": imageVariation,
		sort: "published:desc"
	});

	const langChange = (target) => {
		const langPath = `/l/${target}`;
		const locationPath = location.pathname;
		const setPath = `${langPath}${locationPath}`;

		window.location.href = setPath;

	}

	const onlyWidth = useWindowWidth();
	const langMenu = (
		<ul className="p-langSelect__list">
			<li className="p-langSelect__item"><span onClick={() => langChange('ja-JP')} className="p-langSelect__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-lang-ja.svg')} alt="Japanese" className="p-langSelect__img--ja"/></span></li>
			<li className="p-langSelect__item"><span onClick={() => langChange('en-US')} className="p-langSelect__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-lang-en.svg')} alt="English" className="p-langSelect__img--en"/></span></li>
			<li className="p-langSelect__item"><span onClick={() => langChange('ko-KR')} className="p-langSelect__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-lang-ko.svg')} alt="Korean" className="p-langSelect__img--ko"/></span></li>
			{/* <li className="p-langSelect__item"><span onClick={() => langChange('ja-JP')} className="p-langSelect__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-lang-zh-Hans.svg')} alt="Simplified Chinese" className="p-langSelect__img--zh-Hans"/></span></li>
			<li className="p-langSelect__item"><span onClick={() => langChange('ja-JP')} className="p-langSelect__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-lang-zh-Hant.svg')} alt="Traditional Chinese" className="p-langSelect__img--zh-Hant"/></span></li> */}
		</ul>
	);

	useEffect(() => {
		setLangModal(false);
		setNavOpen(false);
		setToggleLock(false)
	},[location.pathname])

	useEffect( () => {
		if (onlyWidth <= 767 || langModal === true) {
			setToggleLock(true);
		} else {
			setToggleLock(false)
		}
	},[onlyWidth, langModal]);

	useEffect( () => {
		setLangModal(false);
		setNavOpen(false);
		setScrollLock(false)
	},[onlyWidth]);


	const buildMenuIcon = () => {
		if (navOpen === false) {
			return <span className="c-navGlobal-toggleBtn__closed" style={{
				backgroundImage: `url(${require('assets/img/rocket-on/img-navDrawer--open.svg')})`
			}}></span>
		} else {
			return <span className="c-navGlobal-toggleBtn__opened" style={{
				backgroundImage: `url(${require('assets/img/rocket-on/img-navDrawer--close.svg')})`
			}}></span>
		}
	}

	const buildLangModal = () => {
		if (langModal === false) {
			return null;
		}
		else {
			return (
				<>
					<div className="modaal-wrapper modaal-inline" id="modaal_160369910180224a4a9e6d3557">
						<div className="modaal-outer-wrapper">
							<div className="modaal-inner-wrapper">
								<div className="modaal-container">
									<div className="modaal-content modaal-focus" aria-hidden="false" aria-label="Dialog Window - Close (Press escape to close)" role="dialog" tabIndex="0">
										<div className="modaal-content-container" role="document">
											<div className="p-langSelect">
												{langMenu}
												<button onClick={() => {handleModal()}} className="p-langSelect__closeBtn js-modal--close"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/btn-langSelect-close.svg')} alt="閉じる"/></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modaal-overlay" id="modaal_160369910180224a4a9e6d3557_overlay" style={{
						background: "rgb(0, 0, 0)",
						opacity: 0.7
					}}></div>
				</>
			)
		}
	}

	const handleModal = () => {
		if (langModal === false) {
			setScrollLock(true)
			setLangModal(true)
		}
		else {
			setScrollLock(false)
			setLangModal(false)
		}
	}
	
	const handleNav = () => {
		if (navOpen === false) {
			setScrollLock(true)
			setNavOpen(true);
		} else {
			setScrollLock(false)
			setNavOpen(false);
		} 
	}

	const handleLock = () => {

		if (toggleLock) {
			return (
				<ScrollLock isActive={scrollLock} />
			)
		} else {
			return null;
		}

	}

	const buildTopics = () => {

		if (topicsList === null) return false;
		
		if (topicsList.data.data.length === 0) {
			return <p style={{textAlign: "center"}}>coming soon</p>
		}
		else {		
			return topicsList.data.data.map((item, idx) => {
				return (
					<li key={idx} className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/topics/${item.Slug}`} className="c-navGlobal-ctgList__anchor"><span className="c-navGlobal-pickup__date">{moment(item.Published.unix * 1000).format('YYYY/MM/DD')}</span><span className="c-navGlobal-pickup__heading">{item.Title}</span></a></li>
				)
			})
		}	
	}

	return (
		<>
			<header className={classNames('l-header', {
				'is-home' : location.pathname === "/"
			})}>
				<p className="c-header-logo"><a href={`https://www.ihic.jp/l/${defaultLng}/`}><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/logo.svg')} alt="産業遺産情報センター" className="c-header-logo__img" /></a></p>
				<nav className="c-navGlobal">
					<p className="c-navGlobal-lang">
						<button type="button" onClick={() => {handleModal()}} className="c-navGlobal-lang__anchor js-modal--open" data-source="#langSelect" data-opacity=".7"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/icon-earth.svg')} alt="" className="c-navGlobal-lang__icon"/>Language</button>	
					</p>
					<ul className="c-navGlobal-digest">
						<li className="c-navGlobal-digest__item"><a href={`https://www.ihic.jp/l/${defaultLng}/guide`} className="c-navGlobal-digest__anchor">{t('common_visiting')}</a></li>
						<li className="c-navGlobal-digest__item"><a href={`https://www.ihic.jp/l/${defaultLng}/about-us`} className="c-navGlobal-digest__anchor">{t('common_header_ihic')}</a></li>
						<li className="c-navGlobal-digest__item"><a href={`https://www.ihic.jp/l/${defaultLng}/events`} className="c-navGlobal-digest__anchor">{t('common_event_title')}</a></li>
					</ul>
					<p className="c-navGlobal-toggleBtn">
						<button
							className={classNames("c-navGlobal-toggleBtn__btn js-navGlobal--toggle", {
								'is-opened': navOpen === true
							})}
							onClick={() => {handleNav()}}
						>{buildMenuIcon()}</button>
					</p>
					<TouchScrollable>
						<div className={classNames("c-navGlobal-toggleBody js-navGlobal--target", {
							'is-opened': navOpen === true
						})}>
							<ul className="c-navGlobal-list">
								<li className="c-navGlobal-list__ctg">
									<span className="c-navGlobal-list__ctgHeading">{t('common_visiting')}</span>
									<ul className="c-navGlobal-ctgList">
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/guide`} className="c-navGlobal-ctgList__anchor">{t('common_user_guide')}</a></li>
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/access`} className="c-navGlobal-ctgList__anchor">{t('common_access')}</a></li>
										<li className="c-navGlobal-ctgList__item"><a href="https://booking.ihic.jp/" className="c-navGlobal-ctgList__anchor">{t('common_booking')}</a></li>
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/faq`} className="c-navGlobal-ctgList__anchor">{t('common_faq')}</a></li>
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/manners`} className="c-navGlobal-ctgList__anchor">{t('common_manners')}</a></li>
									</ul>
								</li>
								<li className="c-navGlobal-list__ctg">
									<span className="c-navGlobal-list__ctgHeading">{t('common_information_center')}</span>
									<ul className="c-navGlobal-ctgList">
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/about-us`} className="c-navGlobal-ctgList__anchor">{t('common_about_us')}</a></li>
										<li className="c-navGlobal-ctgList__item"><a href={`https://www.ihic.jp/l/${defaultLng}/exhibition`} className="c-navGlobal-ctgList__anchor">{t('common_what_to_see')}</a></li>
									</ul>
								</li>
								<li className="c-navGlobal-list__ctg is-ctg--pickup">
									<span className="c-navGlobal-list__ctgHeading is-pcOnly">{t('common_event_title')}</span>
									<span className="c-navGlobal-list__ctgHeadingSub is-bordered"><a href={`https://www.ihic.jp/l/${defaultLng}/topics/`} className="c-navGlobal-ctgList__anchor">{t('common_event_list')}</a></span>
									<ul className="c-navGlobal-ctgList c-navGlobal-ctgList--pickup is-pcOnly">
										{buildTopics()}
									</ul>
								</li>
								<li className="c-navGlobal-list__ctg is-ctg--others">
									<span className="c-navGlobal-list__ctgHeading is-pcOnly">&nbsp;</span>
									<ul className="c-navGlobal-ctgList">
										<li className="c-navGlobal-ctgList__item is-bordered"><a href={`https://www.ihic.jp/l/${defaultLng}/info/`} className="c-navGlobal-ctgList__anchor">{t('common_info')}</a></li>
										<li className="c-navGlobal-ctgList__item is-bordered"><a href={`https://www.ihic.jp/l/${defaultLng}/guidebook`} className="c-navGlobal-ctgList__anchor">{t('common_guidebook')}</a></li>
										<li className="c-navGlobal-ctgList__item is-bordered"><a href={`https://www.ihic.jp/l/${defaultLng}/publications`} className="c-navGlobal-ctgList__anchor">{t('common_publications')}</a></li>
										<li className="c-navGlobal-ctgList__item is-bordered"><a href={`https://www.ihic.jp/l/${defaultLng}/industrial`} className="c-navGlobal-ctgList__anchor">{t('common_component')}</a></li>
									</ul>
								</li>
							</ul>
							<div className="c-navGlobal-lang--sp">
								<span className="c-navGlobal-lang__anchor"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/icon-earth.svg')} alt="" className="c-navGlobal-lang__icon"/>Language</span>
								<div id="langSelect" className="forSP">
									<div className="p-langSelect">
										{langMenu}
									</div>
								</div>
							</div>
						</div>
					</TouchScrollable>
				</nav>
				{buildLangModal()}
			</header>
			{handleLock()}
		</>
	);
};
